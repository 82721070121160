/* eslint-disable no-underscore-dangle */
import { parse, validate } from 'fast-xml-parser';

interface KeyDescriptorType {
  KeyInfo?: {
    X509Data?: {
      X509Certificate: string;
    };
  };
}

interface ParsedXml {
  EntityDescriptor?: {
    _entityID: string;

    IDPSSODescriptor?: {
      KeyDescriptor: KeyDescriptorType | KeyDescriptorType[];
      SingleSignOnService?: { _Location: string }[];
    };

    SPSSODescriptor?: {
      KeyDescriptor: KeyDescriptorType | KeyDescriptorType[];
      SingleSignOnService?: { _Location: string }[];
    };
  };
}

export const parseMetadataFromXmlContent = (
  xmlContent: string,
):
  | undefined
  | {
      saml_entity_id: string | undefined;
      saml_idp_url: string | undefined;
      saml_x509_certs: string[] | undefined;
    } => {
  const valid = validate(xmlContent);

  if (!valid) {
    return;
  }

  const parsed = parse(xmlContent, {
    attributeNamePrefix: '_',
    ignoreAttributes: false,
    ignoreNameSpace: true,
  }) as ParsedXml;

  return {
    saml_entity_id: parsed.EntityDescriptor?._entityID,
    saml_idp_url: idpURL(parsed),
    saml_x509_certs: x509Certs(parsed),
  };
};

const descriptor = (parsed: ParsedXml) =>
  parsed.EntityDescriptor?.IDPSSODescriptor ||
  parsed.EntityDescriptor?.SPSSODescriptor;

const idpURL = (parsed: ParsedXml): string | undefined =>
  descriptor(parsed)?.SingleSignOnService?.[0]?._Location;

const x509Certs = (parsed: ParsedXml): string[] | undefined => {
  let keys = descriptor(parsed)?.KeyDescriptor;

  if (!keys) {
    return;
  }

  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  return keys.map((key) => {
    const rawValue = key.KeyInfo?.X509Data?.X509Certificate;

    if (!rawValue) {
      return '';
    }

    return [
      '-----BEGIN CERTIFICATE-----',
      rawValue,
      '-----END CERTIFICATE-----',
    ].join('\n');
  });
};
